import "@/styles/voucher-bookings.scss"
import "@hotwired/turbo"
import { application } from "./application"
import type { TurboSessionWithDrive } from "@/entry_points/types"

import SmoothScroll from "smoothscroll-polyfill"

import ElmModal from "@/components/elm_modal"
import Loader from "@/components/loader"
import "@/components/content/StatusBar"
import { setupAccordions } from "@/components/content/Accordion"
import { Elm } from "@/components/elm/VoucherBookings.elm"
import airbrakeClient, { logDecodingError } from "@/helpers/airbrake"
import { pushGa4Event } from "@/helpers/ga4_helper"
import { loadBackgroundImages } from "@/helpers/image_loader"
import Emarsys from "@/helpers/emarsys"
import getUserData from "@/helpers/user_data"

import CheckoutFormController from "../controllers/checkout/checkout_form_controller"
import EmailFieldController from "../controllers/checkout/email_field_controller"

declare global {
  interface Window {
    elmFlags: Flags
  }
}

SmoothScroll.polyfill()

const initCheckout = async (): Promise<void> => {
  const node = document.getElementById("js-voucher-bookings")
  if (!node) return

  const loader = new Loader()
  loader.start()

  const CAROUSEL_IMG_SELECTOR = ".ui-component-carousel [data-img]"
  const modal = new ElmModal({ modalId: "elm-product-modal" })
  const flags = {
    ...window.elmFlags,
    userData: await getUserData()
  }
  const app = await Elm.VoucherBookings.init({ node, flags })

  loader.stop()

  app.ports.pageChange.subscribe(() => {
    document.body.scrollIntoView({ behavior: "smooth" })
  })

  app.ports.pushGa4Event.subscribe(pushGa4Event)

  app.ports.flagDecodingError.subscribe(() => logDecodingError(flags))

  app.ports.reportError.subscribe((err: string) => {
    void airbrakeClient.notify({ error: "Error in vochers Elm app", params: { err } })
  })

  app.ports.selectCounterValue.subscribe((e: Event) => {
    ;(e.target as HTMLInputElement)?.select()
  })

  app.ports.modalOpened.subscribe((isOpen: boolean) => {
    modal.trapFocus(isOpen)

    window.requestAnimationFrame(() => {
      loadBackgroundImages(document.querySelectorAll(CAROUSEL_IMG_SELECTOR))
    })
  })

  app.ports.carouselActivated.subscribe((carouselId: string) => {
    loadBackgroundImages(document.querySelectorAll(`#${carouselId} .js-img`))
  })

  app.ports.updateAmountParam.subscribe((amount: string) => {
    const url = new URL(window.location.href)
    const params = url.searchParams

    params.set("amount", amount)
    url.search = params.toString()
    window.history.replaceState({}, "", url.toString())
  })

  app.ports.sendEmarsysData.subscribe(Emarsys)
}

void initCheckout()

const setupStimulus = (): void => {
  application.register("checkout-form", CheckoutFormController)
  application.register("email-field", EmailFieldController)
}

const setupTurbo = (): void => {
  const turboSession = Turbo.session as TurboSessionWithDrive
  turboSession.drive = false
}

setupAccordions()
setupStimulus()
setupTurbo()
