const MAILGUN_URI = "https://api.mailgun.net/v3/address/validate"

export class EmailValidity {
  private isValid: boolean
  private mailboxVerification?: string | null
  private didYouMean?: string | null

  constructor({
    /* eslint-disable @typescript-eslint/naming-convention */
    is_valid,
    mailbox_verification,
    did_you_mean
    /* eslint-enable @typescript-eslint/naming-convention */
  }: {
    is_valid: boolean
    mailbox_verification?: string | null
    did_you_mean?: string | null
  }) {
    this.isValid = is_valid
    this.mailboxVerification = mailbox_verification
    this.didYouMean = did_you_mean
  }

  valid(): boolean {
    return this.isValid && this.deliverable()
  }

  message(): string {
    if (this.hasSuggestion()) {
      return `Did you mean ${this.didYouMean}?`
    } else if (!this.deliverable()) {
      return "Provided email address does not exist."
    } else {
      return "Your email address is invalid. Please check."
    }
  }

  private deliverable(): boolean {
    return this.mailboxVerification !== "false"
  }

  private hasSuggestion(): boolean {
    return typeof this.didYouMean === "string"
  }
}

export class Mailgun {
  readonly apiKey: string

  constructor(apiKey: string) {
    this.apiKey = apiKey
  }

  generateUrl(email: string): string {
    return `${MAILGUN_URI}?mailbox_verification=true&api_key=${this.apiKey}&address=${email}`
  }

  async validate(email: string): Promise<EmailValidity> {
    const response = await fetch(this.generateUrl(email))

    if (response.ok) {
      return new EmailValidity(await response.json())
    } else {
      return new EmailValidity({ is_valid: true })
    }
  }
}
