import { Controller } from "@hotwired/stimulus"

const HIDDEN_FIELD_SELECTOR = "input[type=hidden][name=form_validity]"
const PAYMENT_BUTTON_SELECTOR = "button[form=checkout-form][type=submit]"
const ICON_SELECTOR = ".far"

export default class CheckoutFormController extends Controller<HTMLFormElement> {
  static targets = ["email", "telephone"]

  declare readonly telephoneTarget: HTMLInputElement

  connect(): void {
    this.clearLoading()
  }

  submit(event: Event): void {
    this.dispatch("validate")

    if (!this.checkValidity()) {
      document.getElementById("checkout-form-has-errors")?.removeAttribute("hidden")
      event.preventDefault()
    } else {
      this.setLoading()
    }
  }

  setLoading(): void {
    const button = document.querySelector(PAYMENT_BUTTON_SELECTOR) as HTMLButtonElement
    const icon = button.querySelector(ICON_SELECTOR)
    button.disabled = true
    icon?.classList.remove("fa-arrow-right")
    icon?.classList.add("fa-spinner", "fa-spin")
  }

  clearLoading(): void {
    const button = document.querySelector(PAYMENT_BUTTON_SELECTOR) as HTMLButtonElement
    const icon = button.querySelector(ICON_SELECTOR)

    button.disabled = false
    icon?.classList.add("fa-arrow-right")
    icon?.classList.remove("fa-spinner", "fa-spin")
  }

  checkValidity(): boolean {
    return this.element.checkValidity() && this.checkHiddenFieldsValid()
  }

  checkHiddenFieldsValid(): boolean {
    const hiddenInputs = Array.from(
      this.element.querySelectorAll(HIDDEN_FIELD_SELECTOR)
    ) as HTMLInputElement[]

    return hiddenInputs.every((hiddenInput) => hiddenInput.validity.valid)
  }
}
