import { Controller } from "@hotwired/stimulus"
import { Mailgun } from "@/helpers/mailgun"

const EMPTY_FIELD_MESSAGE = "Please fill this field"
const EMAIL_INCORRECT_FORMAT = "Provided email address is incorrect."
const ERROR_SELECTOR = ".utils\\:error"

export default class EmailFieldController extends Controller<HTMLInputElement> {
  private mailgun: Mailgun | null = null

  static values = { mailgunKey: String, serverError: String }

  declare mailgunKeyValue: string
  declare hasMailgunKeyValue: boolean
  declare readonly serverErrorValue: string

  connect(): void {
    if (this.hasMailgunKeyValue) {
      this.mailgun = new Mailgun(this.mailgunKeyValue)
    }

    this.element.setCustomValidity(this.serverErrorValue)

    if (this.serverErrorValue !== "") {
      this.element.focus()
    }
  }

  async validate(): Promise<void> {
    if (!this.element.validity.typeMismatch && this.mailgun) {
      // Only call Mailgun if browser email validation has passed

      const emailValidity = await this.mailgun.validate(this.element.value)

      if (emailValidity.valid()) {
        this.element.setCustomValidity("")
      } else {
        this.element.parentElement?.classList.add("auto-validate")
        this.element.setCustomValidity(emailValidity.message())
        this.setEmailErrorMessage()
      }
    } else {
      this.element.setCustomValidity("")
    }
    this.element.checkValidity()
  }

  setEmailErrorMessage(): void {
    this.element.checkValidity()
    const errorSpan = this.element.parentElement!.querySelector(ERROR_SELECTOR)!

    if (this.element.validity.typeMismatch) {
      errorSpan.textContent = EMAIL_INCORRECT_FORMAT
    } else if (this.element.validity.valueMissing) {
      errorSpan.textContent = EMPTY_FIELD_MESSAGE
    } else if (this.element.validity.customError) {
      errorSpan.textContent = this.element.validationMessage
    }
  }
}
