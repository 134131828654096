const defaultHeaders = (): Headers => {
  const headers = new Headers()
  headers.set("X-Requested-With", "XMLHttpRequest")
  return headers
}

const fetchUrl = async (url: string): Promise<Record<string, string>> => {
  const params: RequestInit = {}
  params.headers = defaultHeaders()

  const response = await fetch(url, params)
  const json = await response.json()

  if (response.status >= 400) {
    return await Promise.resolve({ errors: json })
  } else {
    return json
  }
}

const fetchPostJsonCsrf = (url: string, data = {}, method = "POST"): Promise<Response> => {
  const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content")
  return fetchPostJson(url, data, method, token)
}

const fetchPostJson = (
  url: string,
  data = {},
  method = "POST",
  token: string | null | undefined = null
): Promise<Response> => {
  const params: RequestInit = {
    credentials: "same-origin",
    body: JSON.stringify(data),
    method: method
  }

  const headers = defaultHeaders()
  headers.set("Content-Type", "application/json")
  if (token) {
    headers.set("X-CSRF-Token", token)
  }
  params.headers = headers

  return fetch(url, params)
}

export { fetchUrl, fetchPostJson, fetchPostJsonCsrf }
