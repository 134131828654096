import "@/styles/ui_components/_status-bar.scss"

const STATUS_BAR_SELECTOR = ".ui-component-status-bar"
const STICKY_CLASS = "is-sticky"

const intersectionObserver = new window.IntersectionObserver(
  ([entry]) => {
    const { target, rootBounds, boundingClientRect } = entry

    if (rootBounds) {
      target.classList.toggle(STICKY_CLASS, boundingClientRect.bottom > rootBounds.bottom)
    }
  },
  {
    rootMargin: "0px 0px -1px 0px",
    threshold: 1
  }
)

const observeIntersectionOf = (el: Element | null): void => {
  if (el) {
    intersectionObserver.observe(el)
  }
}

const mutationObserver = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.type === "childList") {
      observeIntersectionOf(document.querySelector(STATUS_BAR_SELECTOR))
    }
  })
})

mutationObserver.observe(document.body, { subtree: true, childList: true })
observeIntersectionOf(document.querySelector(STATUS_BAR_SELECTOR))
